.wrapper {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgb(0, 0, 0, 0);
	z-index: 10000;
}

.wrapper > span {
	display: inherit;
	margin: 0 auto;
}

.wrapperPage {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	background-color: rgb(0, 0, 0, 30%);
	z-index: 10000;
}

.wrapperPage > span {
	display: contents !important;
	margin: 0 auto;
}
