.FirstRow {
	background-color: var(--appMainColor);
	font-weight: 600;
	color: white;
	text-align: center;
	vertical-align: middle;
}
.FirstRow:hover td {
	background-color: var(--appMainColor);
}
