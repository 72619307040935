.QuestionRow {
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 10px;
	color: #808080;
	justify-content: center;
}

.QuestionRow div > label {
	margin-bottom: 5px;
}

.QuestionImage {
	width: auto;
}
