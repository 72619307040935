.TableRow {
	margin-bottom: 10px;
	margin-left: 10px;
}

.TestTable {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	overflow: hidden;
}

.TestTablebody {
	text-align: left;
}

.TestTablebody td,
.TestTable th {
	padding: 0.3rem 1rem;
}

.TestTablebody td,
.TestTable th {
	padding: 0.3rem 1rem;
}
