@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Light"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
	font-weight: 100;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Semilight"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
	font-weight: 200;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
	font-weight: 400;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Semibold"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Bold"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
	font-weight: 700;
}

.App {
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	font-family: "SegoeUI" !important;
}

.AppIcon {
	font-size: 20px;
}
.AppIcon:hover {
	color: var(--appMainColor);
}

.AppContainer {
	width: 100%;
}

/* SideBar */
.SideBarLink {
	color: rgb(255, 255, 255);
	text-decoration: none;
	text-align: center;
	width: 100%;
	padding: 5px;
	border: 5px solid transparent;
	justify-content: center;
	height: 100px;
	display: table-cell;
	vertical-align: middle;
}

.SideBarLink:hover,
.ActiveSideBarLink:hover {
	border: 5px solid;
	background: var(--appMainColor);
	border-left-color: #ffffff;
	color: #ffffff;
	text-decoration: none;
	border-bottom: none;
	border-top: none;
	border-right: none;
}

.ActiveSideBarLink {
	border: 5px solid;
	background: #0095a7 !important;
	border-left-color: #ffffff;
	color: #ffffff;
	text-decoration: none;
	border-bottom: none;
	border-top: none;
	border-right: none;
	text-align: center;
	width: 100%;
	padding: 5px;
	justify-content: center;
	height: 100px;
	display: table-cell;
	vertical-align: middle;
}

/* Media for responsive side nav bar */
@media (max-width: 1000px), (max-height: 880px) {
	.SideBarLink,
	.SideBarLink:hover,
	.ActiveSideBarLink,
	.ActiveSideBarLink:hover {
		height: 50px;
	}
}
@media (max-height: 524px) {
	.SideBarLink,
	.SideBarLink:hover,
	.ActiveSideBarLink,
	.ActiveSideBarLink:hover {
		height: 40px;
	}
}
