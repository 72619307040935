.QuestionRow {
	margin-bottom: 10px;
	margin-left: 10px;
	color: #808080;
}

.QuestionRow div > label {
	margin-bottom: 5px;
}

.QuestionLabel,
.QuestionLabelInValid {
	padding: 0px;
	margin-bottom: 10px;
	font-weight: 600;
}

.QuestionLabel {
	color: black !important;
}

.QuestionLabelInValid {
	color: #dc3545 !important;
}
