.divBoxContent {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.headLabel {
	margin-bottom: 20px;
	line-height: 1.2;
	color: rgba(0, 0, 0, 0.55);
}
