.BasicAppButton {
	background-color: var(--appMainColor) !important;
	color: #fff !important;
	border: 1px solid var(--appMainColor) !important;
}

.BasicAppButton:hover,
.BasicAppButton:disabled {
	background-color: var(--appSecondaryColor) !important;
	color: #fff !important;
	border: 1px solid var(--appSecondaryColor) !important;
}
