.QuestionRow {
	margin-bottom: 10px;
	margin-left: 10px;
	color: #808080;
}

.QuestionRow div > label {
	margin-bottom: 5px;
}

.QuestionLabel,
.QuestionLabelInValid {
	padding: 0px;
	margin-bottom: 10px;
	font-weight: 600;
}

.QuestionLabel {
	color: black !important;
}

.QuestionLabelInValid {
	color: #dc3545 !important;
}

.inputContainer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 10px;
}

.inputlabel {
	margin-left: 10px;
	flex: 1;
	white-space: normal;
}

.percentageInput {
	width: 100px !important;
}
