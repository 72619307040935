.AppHeader {
	display: inline-block;
	width: 100%;
	background: rgb(239 245 245);
	position: sticky;
	top: 0;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 10px;
	height: auto;
	z-index: 1000;
	padding-left: 20px;
}

.LogoutBtn {
	margin-top: "auto";
	margin-bottom: "auto";
	margin-right: 20px;
	padding: 5px 30px 5px 30px;
	border-radius: 30px;
	background-color: var(--appMainColor);
	border: 1px solid var(--appMainColor);
	transition: ease 0.3s;
	-webkit-transition: ease 0.3s;
}
.LogoutBtn > a {
	font-size: 16px;
	color: rgb(255, 255, 255) !important;
	text-decoration: none !important;
}

.LogoutBtn:hover {
	background-color: white !important;
	cursor: pointer;
}
.LogoutBtn:hover > a {
	color: var(--appMainColor) !important;
}
