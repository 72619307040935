.backButton {
	background-color: var(--appMainColor) !important;
	color: #fff !important;
	border: 1px solid var(--appMainColor) !important;
}

.backButton:hover,
.backButton:disabled {
	background-color: #4bbabc !important;
	color: #fff !important;
	border: 1px solid #4bbabc !important;
}
